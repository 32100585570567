.page404 {
	height:calc(100vh - 100px);
  .ant-layout-content {
    overflow: hidden !important;
  }
  .me404 {
    width: 1000px;
    height: 480px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -400px;
    margin-top: -240px;
  }

  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #e8ebed;
  }
  .st1 {
    fill: #ffffff;
    stroke: #89949b;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #dbdfe1;
  }
  .st3 {
    fill: #ffffff;
  }

  .st4 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #e8ebed;
    stroke: #89949b;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st5 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
    stroke: #89949b;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st6 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #89949b;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st7 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #89949b;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st8 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
    stroke: #89949b;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st9 {
    fill: #89949b;
  }
  .st10 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #89949b;
  }

  .st11 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #89949b;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st12 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
  }

  .st13 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
    stroke: #8894a0;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st14 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #89949b;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st15 {
    fill: none;
    stroke: #89949b;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  #monkey-eye-l {
    transform-origin: 191px 257px;
    animation: blink-l 12s infinite ease-in-out;
  }
  #monkey-eye-r {
    transform-origin: 205px 256px;
    animation: blink-r 12s infinite ease-in-out;
  }
  @keyframes blink-l {
    0% {
      transform: rotateX(0deg);
    }
    2% {
      transform: rotateX(80deg);
    }
    4%,
    20% {
      transform: rotateX(0deg);
    }
    22% {
      transform: rotateX(80deg);
    }
    24%,
    30% {
      transform: rotateX(0deg);
    }
    32% {
      transform: rotateX(80deg);
    }
    34%,
    70% {
      transform: rotateX(0deg);
    }
    72% {
      transform: rotateX(80deg);
    }
    74%,
    100% {
      transform: rotateX(0deg);
    }
  }
  @keyframes blink-r {
    0% {
      transform: rotateX(0deg);
    }
    2% {
      transform: rotateX(80deg);
    }
    4%,
    30% {
      transform: rotateX(0deg);
    }
    32% {
      transform: rotateX(80deg);
    }
    34%,
    50% {
      transform: rotateX(0deg);
    }
    52% {
      transform: rotateX(80deg);
    }
    54%,
    100% {
      transform: rotateX(0deg);
    }
  }
}
