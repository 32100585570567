html,
body {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.sys-head {
	height: 60px;
	width: 100vw;
	background: #1d7dfc;
}

.sys-center {
	height: calc(100vh - 60px);
	display: flex;

	.sys-sidebar {
		width: 200px;
		height: 100%;
		overflow: hidden auto;
	}

	.sys-container {
		width: calc(100vw - 200px);
		height: 100%;
		background: #f0f2f5;
		padding: 24px;


	}
}

//模块顶部按钮栏
.sys-main-operate {
	height: 55px;
	clear: both;


	.ant-form {
		float: left;
		display: flex;

		.ant-form-item {
			width: 230px;
			margin-right: 10px;
		}

		.form-select {
			width: 100px;
		}
	}




	.btn {
		float: right;
		margin-left: 15px;
	}
}

//表格  需要每个页面定义最大高度
// thead .ant-table-cell {
// 	text-align: center !important;

// 	&:first-child {
// 		text-align: left !important;
// 	}
// }
// .ant-table table{
// 	visibility: visible !important;
// }
.sys-table .ant-table {
	overflow: hidden auto;
	height: calc(100vh - 220px);



}

// .ant-empty {
// 	margin-top: calc(50vh - 180px);
// }

.ant-table-placeholder .ant-table-cell {
	border-bottom-width: 0;
}


//必填项
.sys-required {
	line-height: 30px;

	&:before {
		display: inline-block;
		margin-right: 4px;
		color: #ff4d4f;
		font-size: 14px;
		font-family: SimSun, sans-serif;
		line-height: 1;
		content: '*';
	}
}



//树的选中
.ant-tree-treenode {
	padding: 0 !important;
	margin-bottom: 4px;
}


.ant-tree-node-selected {
	background: #d2e5fc !important;
	border-radius: 4px;
}
