.RelationCheckbox .ant-checkbox-inner {
  border-radius: 50%;
}

.asset-status {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

// 用于最小值-最大值...
.sys-range {
  >div {
    margin-left: -1px;
    float: left;
  }

  >div:first-child {
    margin-left: 0;
  }
}
