/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@import 'animate.css';
@font-face {
  font-family: 'Icomoon';
  src: url('../fonts/icomoon/icomoon.eot');
  src: url('../fonts/icomoon/icomoon.eot?#iefix') format('embedded-opentype'), url('../fonts/icomoon/icomoon.woff') format('woff'), url('../fonts/icomoon/icomoon.ttf') format('truetype'), url('../fonts/icomoon/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
.ad {
  position: relative;
  display: inline-block;
  font: normal normal normal 16px/1 Icomoon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.ad-lines:before {
  content: '\e600';
}
.ad-wand:before {
  content: '\e010';
}
.ad-radio-tower:before {
  content: '\f030';
}
.ad-ruby:before {
  content: '\f047';
}
.ad-screen-full:before {
  content: '\f066';
}
.ad-home:before {
  content: '\e021';
}
.ad-image:before {
  content: '\e90d';
}
.ad-camera:before {
  content: '\e90f';
}
.ad-play:before {
  content: '\e912';
}
.ad-equalizer:before {
  content: '\e992';
}
.ad-headphones:before {
  content: '\e910';
}
.ad-message:before {
  content: '\e945';
}
.ad-mail:before {
  content: '\f0e0';
}
.ad-man:before {
  content: '\e055';
}
.ad-woman:before {
  content: '\e056';
}
.ad-user:before {
  content: '\e972';
}
.ad-ring:before {
  content: '\f0f3';
}
.ad-gear:before {
  content: '\e994';
}
.ad-increase:before {
  content: '\ea7b';
}
.ad-decrease:before {
  content: '\ea7c';
}
.ad-users:before {
  content: '\e973';
}
.ad-poweroff:before {
  content: '\f011';
}
.ad-check:before {
  content: '\e207';
}
.ad-close:before {
  content: '\e208';
}
.ad-into:before {
  content: '\e161';
}
.ad-trash:before {
  content: '\f014';
}
.ad-minus:before {
  content: '\f068';
}
.ad-plus:before {
  content: '\ea0a';
}
.ad-refresh:before {
  content: '\e030';
}
.ad-sync:before {
  content: '\e082';
}
.ad-enlarge:before {
  content: '\e98b';
}
.ad-shrink:before {
  content: '\e98c';
}
.ad-edit:before {
  content: '\e151';
}
.ad-buret:before {
  content: '\e091';
}
.ad-finder:before {
  content: '\eabf';
}
.ad-download:before {
  content: '\e960';
}
.ad-upload:before {
  content: '\e961';
}
.ad-info:before {
  content: '\f129';
}
.ad-exclamation:before {
  content: '\f12a';
}
.antui-icon.spin:before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
@keyframes loadingCircle {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: 50% 50%;
    transform: rotate(360deg);
  }
}
html,
body,
:global(#root) {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #f0f2f5;
}
@media print {
  .ant-checkbox-checked .ant-checkbox-inner {
    background: transparent !important;
    border-color: #333 !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #333 !important;
  }
}
@media (min-width: 576px) {
  /* 滚动条样式 */
  ::-webkit-scrollbar {
    z-index: 30;
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #eaeaea;
    border-left: 1px solid #c1c1c1;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0;
    box-shadow: none;
    border: 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    box-shadow: none;
    border: 0;
  }
}
body {
  font-family: 'Monospace Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  color: #666;
}
a:active {
  color: #096dd9;
}
a:hover {
  color: #1890ff;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 1px;
}
/* 重写antd默认布局样式 */
.ant-layout .ant-layout-header {
  height: auto;
  line-height: normal;
  padding: 0;
  background: #fff;
  overflow: hidden;
}
.ant-layout .ant-layout-content {
  position: relative;
  min-height: auto;
}
.ant-layout .ant-layout-footer {
  text-align: center;
  width: 100%;
  background-color: #fff;
  padding: 10px;
}
.page.ant-layout > .ant-layout-content {
  padding: 24px 24px 0px;
}
.nobr {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.clearfix {
  zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}
.clearfix:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.flex {
  display: flex;
  height: 100%;
  position: relative;
}
.flex-column {
  flex-direction: column;
}
.flex-auto {
  flex: auto;
  position: relative;
  height: 100%;
}
.flex-auto-hidden {
  flex: auto;
  position: relative;
  height: 100%;
  overflow: hidden;
}
.flex-none {
  flex: none;
  position: relative;
}
.abs {
  position: absolute;
}
.abs-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.inline-block {
  display: inline-block;
}
.site-layout-background {
  background: #fff;
}
